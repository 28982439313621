* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  background-color: #f0f2f5;
  padding: 10px;
  overflow: hidden;
  height: 100vh;
}

header {
  width: 100%;
  height: 7vh;
  min-height: 80px;
  padding: 15px;
  border-radius: 8px;
  background-color: #222222;
}

/* hide pagination number of row selector */
.sc-lnsjTu,
.sc-ilxebA {
  display: none !important;
}

.sidebar {
  width: 250px;
  background-color: white;
  border-radius: 10px;
}

.logo {
  width: 50%;
  max-width: 200px;
  margin: 30px;
}

.login-form {
  width: 50%;
  max-width: 500px !important;
  height: 50%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  align-items: center;
  border-radius: 10px;
}

.login-form {
  width: 100%;
  margin-inline: auto;
  max-width: 350px;
  padding: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  margin-block: 10%;
}

.login-form .container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-evenly;
}

.selecteds {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex: 1 1 0%;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.selected {
  align-items: center;
  padding: 5px;
  gap: 5px;
  display: flex;
  min-width: 0px;
  background-color: rgb(230, 230, 230);
  border-radius: 2px;
  margin: 2px;
  box-sizing: border-box;
}

.selected p {
  margin: 0px;
  overflow: hidden;
  white-space: break-spaces;
  border-radius: 2px;
  color: rgb(51, 51, 51);
  font-size: 85%;
  padding: 3px 3px 3px 6px;
  box-sizing: border-box;
}

.selected button {
  outline: none;
  border: none;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  border-radius: 2px;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
}

.selected button:hover {
  background-color: rgb(255, 189, 173);
  color: rgb(222, 53, 11);
}

.btn-group {
  gap: 5px;
}

.main {
  padding-bottom: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 83vh;
  width: 100%;
}

.sidebar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 10px;
}

.sidebar button {
  width: 220px;
  height: 55px;
  padding: 15px;
  border-radius: 8px;
  color: #222222;
  background-color: white;
  outline: none;
  border: none;
}

.sidebar .active {
  color: white;
  background-color: #222222;
}

.MuiModal-backdrop {
  background-color: transparent !important;
}
